:root {
  --primary-blue: #0055ff;
  --primary-orange: #ff502f;
  --border-color: #efefef;
  --primary-shadow-color: rgba(0,0,0,0.2);
  --secondary-shadow-color: rgba(0,0,0,0.13);
  --tertiary-shadow-color: rgba(0,0,0,0.08);
  --general-width: 640px;
  --unpadded-width: 700px;
  --border-radius: 8px;
}

.App {
  text-align: center;
}

.dashboard {
  transition: all 0.5s ease;
}

.forum {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: var(--general-width);
  display: flex;
  flex-direction: column;
  align-items: left;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--secondary-shadow-color);
  padding: 30px;
  transition: all 0.5s ease;
}

.forum-header {
  margin: auto;
  display: flex;
  text-align: center;
  max-width: var(--unpadded-width);
  transition: all 0.5s ease;
  justify-content: center;
}

.forum-post {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.post-header {
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  width: 100%;
}

.post-title {
  display: flex;
  align-self: flex-start;
  text-align: left;
}

.post-text-short {
  display: flex;
  align-self: flex-start;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  height: 50px;
}

.post-text-long {
  display: flex;
  align-self: flex-start;
  text-align: left;
  min-height: 50px;
  margin-bottom: 20px;
}

.post-image {
  width: 100%;
  margin-bottom: 15px;
}

.post-footer {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  text-align: right;
  height: 80px;
  justify-content: space-evenly;
}

.post-footer-btns {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.post-report {
  display: flex;
  align-self: flex-end;
  text-align: right;
  margin-top: 10px;
}

.post-delete {
  display: flex;
  align-self: flex-end;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
}

.post-hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: stretch;
}

.comment-input {
  max-width: var(--unpadded-width);
}

.comment-section {
  width: 100%;
  margin: auto;
  margin-top: 25px;
  align-items: left;
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease;
  word-wrap: break-word;
}

.comment-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-text {
  background: var(--border-color);
  position: relative;
  padding: 16px 16px;
  font-size: 16px;
  border-radius: var(--border-radius);
  list-style: none;
  float: left;
  clear: both;
  margin-bottom: 10px;
  word-wrap: break-word;
}

.comment-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  font-size: 14px;
}

.character-count {
  color: #888888;
  font-size: 14px;
  text-align: right;
}

.class-header {
  margin: auto;
  margin-top: 20px;
  text-align: center;
}

.class-item {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.class-footer {
  align-self: flex-end;
  text-align: right;
}

.profile-header {
  margin: auto;
  text-align: right;
  max-width: var(--unpadded-width);
  transition: all 0.5s;
}

.profile-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  margin-bottom: 40px;
  max-width: var(--unpadded-width);
  transition: all 0.5s
}

.profile-bugs {
  color: black;
  font-size: 20px;
  margin-right: 10px;
}

.profile-settings {
  color: black;
  font-size: 20px;
}

.profile-text {
  margin-left: 25px;
}

.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  transition: all 0.5s;
}

.post-link {
  width: 100%;
}

.addpost-input {
  margin: auto;
  margin-top: 40px;
  max-width: var(--unpadded-width);
  display: block;
}

.addpost-title {
  resize: none;
}

.addpost-text {
  min-height: 120px;
}

.addpost-file {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
  z-index: -1;
}

.addpost-file + label {
  color: white;
  background-color: var(--primary-orange);
  display: inline-block;
  padding: 15px;
  border-radius: var(--border-radius);
  border: 1px solid var(--primary-orange);
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  min-width: 120px;
  box-shadow: 0 0 6px var(--primary-shadow-color);
  transition: all 0.3s;
}

.addpost-file + label:hover {
  color: var(--primary-orange);
  background-color: white;
}

.addpost-image {
  width: 100%;
  align-self: center;
  display: none;
}

.addpost-image-active {
  margin-bottom: 20px;
  display: block;
}

.like-button {
  display: flex;
  width: 28px;
  flex-direction: row;
  flex-wrap: none;
  justify-content: space-between;
  align-items: center;
}

.like-button-label {
  cursor: pointer;
  align-self: flex-end;
}

.like-checkbox {
  display: none;
}

.joinclass-text {
  font-size: 18px;
}

.joinclass-footer {
  margin: auto;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}

.chat-rules {
  margin: auto;
  text-align: center;
}

.chat-queue {
  margin: auto;
  margin-top: 50px;
  text-align: center;
}

.chatroom {
  box-sizing: border-box;
  max-height: 65vh;
  overflow-y: scroll;
  width: 50%;
  margin: auto;
  transition: all 1s;
}

.chatroom .msg {
  background: var(--background-color);
  position: relative;
  padding: 8px 15px;
  font-size: 16px;
  border-radius: 10px;
  list-style: none;
  float: left;
  clear: both;
  margin: 10px 0;
  max-width: 200px;
  word-wrap: break-word;
}

.chatroom .msg img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 0px;
}

.chatroom .msg.right {
  float: right;
  clear: both;
}

.chat-form {
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 51px;
  margin: auto;
  margin-bottom: 8vh;
  margin-left: -25%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.5s ease;
}

.chat-form input {
  width: 100%;
  height: 100%;
  color: #595f6e;
  background-color: inherit;
  padding-top: 20px;
  border: none;
  outline: none;
  font-size: 16px;
}

.chat-form label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 1px solid black;
}

.chat-form label::after {
  content: "";
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  border-bottom: 1px solid var(--primary-orange);
  transform: translateX(-100%);
  transition: all 0.3s ease;
}

.chat-content {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease;
}

.chat-form input:focus + .chat-label .chat-content,
.chat-form input:valid + .chat-label .chat-content {
  transform: translateY(-150%);
  font-size: 14px;
}

.chat-form input:focus + .chat-label::after,
.chat-form input:valid + .chat-label::after {
  transform: translateX(0%);
}

.settings-header {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  max-width: var(--unpadded-width);
}

.settings-footer {
  margin: auto;
  margin-top: 30px;
  width: 100%;
  text-align: center;
}

.settings-input {
  max-width: var(--general-width);
  transition: all 0.5s ease;
}

.login-intro-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: black;
}

.login-intro-background {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  animation: opacity-fill 1s;
}

#login-logo {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fill 0.5s ease forwards 2s;
  max-width: 80%;
  height: auto;
  display: block;
}
#login-logo path:nth-child(1) {
  stroke-dasharray: 697;
  stroke-dashoffset: 697;
  animation: line-anim 1s ease forwards;
}
#login-logo path:nth-child(2) {
  stroke-dasharray: 489;
  stroke-dashoffset: 489;
  animation: line-anim 1s ease forwards 0.3s;
}
#login-logo path:nth-child(3) {
  stroke-dasharray: 519;
  stroke-dashoffset: 519;
  animation: line-anim 1s ease forwards 0.6s;
}
#login-logo path:nth-child(4) {
  stroke-dasharray: 389;
  stroke-dashoffset: 389;
  animation: line-anim 1s ease forwards 0.9s;
}
#login-logo path:nth-child(5) {
  stroke-dasharray: 299;
  stroke-dashoffset: 299;
  animation: line-anim 1s ease forwards 1.2s;
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

.login-button {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 120px);
  background-color: white!important;
  color: black!important;
  animation: opacity-fill 0.5s ease forwards 2s;
  visibility: hidden;
}

.login-angle-down {
  position: absolute;
  z-index: 1;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  animation: opacity-fill 0.5s ease forwards 2s;
  visibility: hidden;
}

@keyframes opacity-fill {
  from {
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

.login-description-container {
  position: absolute;
  top: 100%;
  left: 0;
  margin: auto;
  width: 100%;
  height: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.login-description {
  display: flex;
  flex-direction: column;
  margin: 50px 20px;
  max-width: 700px;
  text-align: center;
  font-size: 20px;
}

.login-join-container {
  position: absolute;
  top: calc(100% + 1200px);
  left: 0;
  margin: auto;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--primary-blue);
  transition: all 0.3s;
  cursor: pointer;
}

.login-join-container:hover {
  filter: brightness(1.2);
}

.login-join {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 50px 20px;
  text-align: center;
  font-size: 20px;
  color: white;
}

.login-apply-container {
  position: absolute;
  top: calc(100% + 1600px);
  left: 0;
  margin: auto;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--primary-orange);
  transition: all 0.3s;
  cursor: pointer;
}

.login-apply-container:hover {
  filter: brightness(1.2);
}

.login-apply {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 50px 20px;
  text-align: center;
  font-size: 20px;
  color: white;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: var(--primary-blue);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
  z-index: 1;
}

.mobile-nav-top {
  display: none;
  height: 60px;
  width: 100%;
  background-color: var(--primary-blue);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
  z-index: 1;
}

.mobile-nav-bottom {
  display: none;
  height: 60px;
  width: 100%;
  background-color: var(--primary-blue);
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
  z-index: 1;
}

.nav-name {
  display: flex;
  flex-direction: row;
  color: white;
  text-decoration: none;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  transition: all 0.5s;
  font-size: 18px;
}

.nav-logo {
  height: 48px;
  width: 48px;
  color: white;
}

.nav-icons {
  width: 350px;
  padding-left: 27px;
  padding-right: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  transition: all 0.5s;
  font-size: 18px;
}

.nav-icon {
  text-align: center;
  padding: 12px;
  color: white;
  text-decoration: none;
}

.nav-dropdown-icon {
  color: white;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
}

.nav-dropdown-icon:hover {
  filter: brightness(1.2);
}

.mobile-nav-dropdown-icon {
  position: fixed;
  top: 4px;
  right: 18px;
  color: white;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 24px;
  padding: 12px;
}

.nav-dropdown {
  position: absolute;
  top: 60px;
  transform: translateX(60px);
  width: 180px;
  overflow: hidden;
  background-color: white;
  border: 1px solid var(--border-color);
  padding: 8px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
}

.mobile-nav-dropdown {
  position: fixed;
  top: 60px;
  left: 0;
  width: calc(100% - 16px);
  overflow: hidden;
  background-color: white;
  border: 1px solid var(--border-color);
  padding: 8px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
}

.nav-dropdown-item {
  font-size: 16px;
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: all 0.3s;
  padding: 8px;
  color: black;
  text-decoration: none;
  user-select: none;  
}

.nav-dropdown-item:hover {
  background-color: var(--border-color);
}

.nav-img {
  display: block;
  text-align: center;
  height: 22px;
  width: 22px;
  padding: 0;
  border-radius: 50%;
  transition: all 0.5s;
  user-select: none;
}

.nav-dropdown-item-icon {
  padding: 0;
  margin-right: 10px;
  width: 22px!important;
  color: black;
  font-size: 18px;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: 0.3s all;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: 0.3s all;
}

.loading-large {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 120px;
  width: 120px;
}

.loading-small {
  margin: auto;
  text-align: center;
  height: 60px;
  width: 60px;
}

.short-button {
  display: inline-block;
  border-radius: var(--border-radius);
  background-color: var(--primary-blue);
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 8px;
  padding-bottom: 15px;
  padding-top: 15px;
  width: 120px;
  transition: all 0.3s;
  cursor: pointer;
  margin: auto;
}

.short-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

.short-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.3s;
}

.short-button:hover span {
  padding-right: 25px;
}

.short-button:hover span:after {
  opacity: 1;
  right: 0;
}

.long-button {
  display: inline-block;
  border-radius: var(--border-radius);
  background-color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
  color: white;
  text-align: center;
  font-size: 16px;
  padding: 15px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;
  margin: auto;
}

.long-button:hover {
  background-color: white;
  color: var(--primary-blue);
  text-align: center;
}

.width-150 {
  width: 150px;
}

.width-200 {
  width: 200px;
}

.textbox {
  background-color: white;
  margin: auto;
  max-width: var(--general-width);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--secondary-shadow-color);
  padding: 20px 30px;
}

input[type=file] {
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
}

input[type=checkbox] {
  vertical-align: bottom;
  margin-right: 10px;
  font-size: 10px;
  cursor: pointer;
}

input[type=text] {
  width: 100%;
  height: 20px;
  font-size: 16px;
  border: none;
  border-radius: var(--border-radius);
  background-color: white;
}

input[type=text]:focus {
  outline: none;
}

hr {
  border: none;
  background-color: var(--primary-orange);
  color: var(--primary-orange);
  height: 1px;
  width: 100%;
}

textarea {
  width: 100%;
  min-height: 20px;
  height: 20px;
  font-size: 16px;
  resize: vertical;
  border: none;
  border-radius: var(--border-radius);
  background-color: white;
}

textarea:focus {
  outline: none;
}

button {
  transition-duration: 0.3s;
  border: none;
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
}

button:focus {
  outline: none;
}

select {
  font-size: 16px;
  padding: 10px;
  min-width: 200px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: 0 5px 15px 0 var(--tertiary-shadow-color), 0 2px 4px 0 var(--primary-shadow-color);
  background-color: white;
}

select:focus {
  outline: none;
}

li {
  float: left;
}

html, body {
  overflow: auto;
}

body {
  background-color: var(--background-color);
  padding: 80px 20px 20px 20px;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 0.2rem;
}

body::-webkit-scrollbar-track {
  background: white;
}

body::-webkit-scrollbar-thumb {
  background: var(--primary-blue);
}

.chatroom::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1800px) {
  .chat-form {
    margin-left: -32.5%;
  }
}

@media screen and (max-width: 600px) {
  .profile-pic {
    width: 100px;
    height: 100px;
  }
  .chat-form {
    margin-left: -40%;
  }
  .nav-logo {
    display: none;
  }
  .nav-links {
    width: 100%;
  }
  .nav {
    display: none;
  }
  .mobile-nav-top, .mobile-nav-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body {
    padding-bottom: 80px;
  }
}

@media screen and (max-height: 800px) {
  .chatroom {
    max-height: 50vh;
  }
}